/* .slider img {
    height: 60%;
}

.slider div {
    height: 200px;
}

.slickItem {
    display: flex;
    width: 100%;
    justify-content: center;
}*/

.OurWorks {
    min-height: calc(100% - 150px);
    margin-left: auto;
    margin-right: auto;
}


@media screen and (min-width: 600px) {
    .OurWorks {
        width: 600px;
    }
}

h1 {
    font-size: 24px;
    padding-top: 50px;
    padding: 20px;
    text-decoration: none;
}

.Works-img {
    margin: 30px;
}
