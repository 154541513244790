.Background {
    position: absolute; 
    left: 0; 
    top: 0; 
    width: 100%;
    height: 100%; 
    vertical-align: middle;
    align-items: center;
    display: flex; 
    justify-content: center;
}

.image {
    width: 80%;
    /* height: 50%; */
}

@media screen and (min-width: 700px) {
    .image {
        /* width: 900px; */
        height: 80%;
        /* height: 80%; */
    }
}