.Home {
    position: relative;
    /* width: 800px; */
    height: calc(100% - 150px);
}

.familyToothLabel, h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #383838;
}

.dentexLabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    color: #444444;
}

.labels {
    position: absolute;
    width: 100%;
    top: 30%;
    left: 0;
    /* transform: translate(0, -50%); */
}


@media screen and (min-width: 700px) {
    .familyToothLabel {
        font-size: 25px;
    }

    .dentexLabel {
        font-size: 30px;
    }
}