.Contacts {
/*     

    iframe {
      width: 100%;
      padding: 0;
    }

    a {
      font-size: 16px;
      color:aqua;
    } */

    min-height: calc(100% - 150px);
} 

.mapouter {
    width: 100%;
    padding: 10px;
}

@media screen and (min-width: 600px) {
    .OurWorks {
        width: 600px;
    }

}

.PhoneIcon {
  margin: 4px;
}