.ShowIcon {
    position: fixed;
    left: 15px;
    top: 15px;
    background-color: #F5F5F5;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    padding: 5px;
    z-index: 2;
    cursor: pointer;
    opacity: 0.7;
}

.ShowIcon img {
    width: 40px;
    height: 40px;
}

.Cover {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 1;
    opacity: 1;
}

.Cover-hide {
    z-index: 1;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1s;
    transition-delay: 0s;
}

.DrawerItems {
    position: fixed; 
    top: 0; 
    left:-220px; 
    height: 100%; 
    width: 220px; 
    background: #F3F3F3; 
    overflow-x: hidden; 
    overflow-y: hidden;
    transition-property: left;
    transition-duration: 0.4s;
    transition-delay: 0s;
    z-index: 1;
    text-align: center;
    /* display: flex;
    align-items: center; */
}

.DrawerItems ul {
    margin-top: 190px;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
}

.DrawerItems li {
    margin: 20px;
    padding: 0;

    padding-top: 5px;
    margin-left: 40px;
    /* width: 100%; */
    text-align: left;
    width: 150px;
    height: 40px;
    list-style-type: none;
    cursor: pointer;
}

.DrawerItems svg {
    margin-right: 10px;
    margin-bottom: 5px;
}

.DrawerItem {
    padding-left:10px;
}

.active {
    background: #DEDEDE;
}

a {
    display: block;
    text-decoration: none;
    color: black;
}

.show {
    left: 0px;
}
