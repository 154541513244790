.App {
  width: 100%;
  text-align: center;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

.headerLabel, h1 {
  font-size: 24px;
  padding-top: 50px;
  padding: 20px;
  text-decoration: none;
}

#root {
  display: flex;
  justify-content: center;
}

.App-logo {
  width: 100%;
  pointer-events: none;
}

@media screen and (min-width: 600px) {
  .App-logo {
    width: 600px;
  }

  .PageContent {
    width: 800px;
  }
}

.PageContent {
  display: flex;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.PageContent {
  /* min-height: 100%; */
  display: contents;
}