.Price {
    width: 800px;
    height: auto;
    min-height: calc(100% - 150px);
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width:800px) {
    .Price {
        width: 100%;
    }
}

h1 {
    font-size: 24px;
    padding-top: 50px;
    padding: 20px;
    text-decoration: none;
}

.PriceItem {
    text-align: left;
    margin: 20px;
    font-size: 16px;
    border-bottom: 1px silver solid;
    border-radius: 5px;
}

.PriceItemCount {
    float: right;
    font-style: italic;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 600;
}

.LargePriceItem {
    font-size: 16px;
}

@media screen and (max-width: 600px) {
    .PriceItem {
        font-size: 14px;
    }

    .LargePriceItem {
        font-size: 12px;
    }
}