.Footer {
    width: 100%;
    left: 0;
    height: 150px;
    background: #191919;
    position: relative;
    /* text-align: center; */
}

.privacy {
    color: white;
    font-size: 10px;
    padding-top: 120px;
}

.Footer_dentex-logo {
    position: absolute;
    right: 40px;
}

.Footer_IconsBar {
    position: absolute;
    left: 20px;
    top: 20px;
}

.Footer_IconsBar svg {
    transition: fill .4s ease;
}

.Footer_IconsBar svg:hover {
    fill: #c4c4c4;
}

@media screen and (max-width: 600px) {
    .Footer_dentex-logo {
        /* display: none; */
    }
}

.Footer_icon {
    margin: 5px;
    border-radius: 50%;
    padding-top: 5px;
    width: 40px;
    height: 40px;
    background-color: rgb(48, 48, 48);
}